import React from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'

const LottieShape = ({ loop, autoplay, animationData, size }) => {
  return (
    <Lottie
      options={{
        loop: loop,
        autoplay: autoplay,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={size}
      width={size}
    />
  )
}

LottieShape.defaultProps = {
  loop: true,
  autoplay: true,
  size: 100,
}

LottieShape.propTypes = {
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  animationData: PropTypes.shape({}).isRequired,
  size: PropTypes.number,
}

export default LottieShape
