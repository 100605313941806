const AbstractCongratulationsAnimation = {
  v: '5.2.1',
  fr: 30,
  ip: 0,
  op: 180,
  w: 1920,
  h: 1080,
  nm: 'Congratulations',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'r',
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ['0p667_1_0p333_0'],
                  t: 0,
                  s: [0],
                  e: [-13],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ['0p667_1_0p333_0'],
                  t: 45,
                  s: [-13],
                  e: [20.366],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ['0p667_1_0p333_0'],
                  t: 90,
                  s: [20.366],
                  e: [-17.932],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ['0p667_1_0p333_0'],
                  t: 135,
                  s: [-17.932],
                  e: [0],
                },
                { t: 179 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [124.5, 195, 0], ix: 2 },
            a: { a: 0, k: [102.5, 92, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 115,
          h: 110,
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: 'l',
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ['0p667_1_0p333_0'],
                  t: 0,
                  s: [0],
                  e: [27],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ['0p667_1_0p333_0'],
                  t: 45,
                  s: [27],
                  e: [-11.436],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ['0p667_1_0p333_0'],
                  t: 90,
                  s: [-11.436],
                  e: [26.346],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ['0p667_1_0p333_0'],
                  t: 135,
                  s: [26.346],
                  e: [0],
                },
                { t: 179 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [176.5, 162.5, 0], ix: 2 },
            a: { a: 0, k: [53.5, 139, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 79,
          h: 142,
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [170.684, 218.928, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [4.12, -0.32],
                        [-10.61, -9.61],
                        [-3.2, -16.67],
                        [-5.3, 3.46],
                        [7.42, 12.3],
                        [9.72, 0],
                      ],
                      o: [
                        [-3.53, 7.69],
                        [0, 12.49],
                        [7.31, 6.63],
                        [6.32, 0],
                        [-5, -16.82],
                        [-9.28, -15.37],
                        [0, 0],
                      ],
                      v: [
                        [-18.117, -49.721],
                        [-34.897, -39.151],
                        [-7.507, 1.199],
                        [9.313, 49.719],
                        [34.893, 45.399],
                        [10.603, -20.421],
                        [-13.257, -42.991],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.88627499342, 0.909803986549, 0.949020028114, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [135.305, 175.819, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.54, 7.68],
                        [-3.38, 1.76],
                        [-5.045, 0.783],
                        [4.134, -7.302],
                        [-0.97, -3.38],
                        [-8.69, -14.33],
                        [0.44, -11.05],
                        [2.12, -6.15],
                        [6.31, 0],
                        [-0.34, -2.12],
                        [-2.65, 1.44],
                        [5.3, 15.02],
                        [0.44, 6.24],
                        [-1.379, 6.639],
                        [-1.519, 1.426],
                      ],
                      o: [
                        [4.12, -0.32],
                        [3.53, -7.69],
                        [0, 0],
                        [7.101, -1.103],
                        [1.92, 0.96],
                        [1.32, 4.64],
                        [11.4, 18.79],
                        [-5.01, 1.92],
                        [-5.31, 3.45],
                        [0.51, 2.66],
                        [-24.3, 6.24],
                        [2.65, -1.45],
                        [-3.17, -9],
                        [0, 0],
                        [1.218, -5.863],
                        [0, 0],
                      ],
                      v: [
                        [0.481, 3.96],
                        [17.271, -6.61],
                        [31.841, -21.5],
                        [39.133, -25.219],
                        [52.291, -17.33],
                        [57.601, -2.89],
                        [68.851, 31.95],
                        [86.521, 76.62],
                        [70.281, 88.51],
                        [44.701, 92.83],
                        [45.981, 100.04],
                        [3.131, 100.04],
                        [-4.819, 55.36],
                        [-16.299, 30.38],
                        [-19.467, 16.647],
                        [-9.679, 3.96],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.533333003521, 0.662744998932, 0.913725018501, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Shape 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [162.781, 311.467, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.55, 23.94],
                        [18, -3.7],
                        [4.24, -1.54],
                        [10.12, 0.65],
                        [-2.32, -4.24],
                        [-10.61, 11.56],
                        [-6.18, -1.61],
                        [-10.12, 1.45],
                        [-3.15, 14.5],
                        [-0.12, 0.58],
                      ],
                      o: [
                        [-8.87, 6.17],
                        [-0.96, 18.32],
                        [-4.24, 1.54],
                        [-1.93, 4.98],
                        [2.89, 5.3],
                        [-2.09, 4.3],
                        [7.23, 3.37],
                        [9.74, -1.39],
                        [0.12, -0.56],
                        [2.7, -12.34],
                      ],
                      v: [
                        [46.507, -57.555],
                        [-18.573, -38.755],
                        [-26.763, 18.125],
                        [-62.433, 18.125],
                        [-65.323, 38.375],
                        [-3.623, 37.415],
                        [-2.173, 52.355],
                        [50.367, 57.175],
                        [65.417, 31.405],
                        [65.787, 29.695],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.16, 9.41],
                        [-2.31, -1.54],
                        [12.42, 2.57],
                      ],
                      o: [
                        [5.95, 14.46],
                        [2.31, 1.55],
                        [6.28, -11.82],
                      ],
                      v: [
                        [15.174, -9.831],
                        [35.904, 35.479],
                        [1.534, 29.699],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.157, 0.2, 0.247, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape',
              np: 4,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 0,
          nm: 'head',
          refId: 'comp_3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [127, 145, 0], ix: 2 },
            a: { a: 0, k: [31, 41.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 62,
          h: 83,
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Path 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [142.416, 364.2, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.31, 0.48],
                        [-3.21, -2.73],
                        [0.32, -5.78],
                        [0.97, -3.86],
                        [2.09, 1.28],
                      ],
                      o: [
                        [4.24, -0.39],
                        [-3.47, 2.7],
                        [-5.3, 1.45],
                        [-0.77, 3.08],
                        [-2.41, -11.57],
                      ],
                      v: [
                        [1.58, -28.319],
                        [16.52, -20.609],
                        [11.22, 0.121],
                        [-9.03, 26.641],
                        [-16.26, 26.641],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.639216005802, 0.756862998009, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Path 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [81.897, 348.273, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.31, 0.48],
                        [-3.21, -2.73],
                        [0.32, -5.78],
                        [0.97, -3.85],
                        [2.09, 1.29],
                      ],
                      o: [
                        [4.24, -0.39],
                        [-3.47, 2.7],
                        [-5.3, 1.45],
                        [-0.77, 3.09],
                        [-2.41, -11.57],
                      ],
                      v: [
                        [1.58, -28.319],
                        [16.52, -20.609],
                        [11.22, 0.121],
                        [-9.03, 26.631],
                        [-16.26, 26.631],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [0.639216005802, 0.756862998009, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [31.674, 26.248, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.44, -1.48],
                        [-0.02, -1.36],
                        [0.93, -3.02],
                        [-6.81, -1.53],
                        [-0.71, -0.93],
                        [-2.65, 6.92],
                      ],
                      o: [
                        [-0.06, 1.13],
                        [-2.86, -0.98],
                        [-4.42, 14.08],
                        [0.58, 1.07],
                        [-22.12, -1.99],
                        [2.12, -5.54],
                      ],
                      v: [
                        [-15.481, -13.493],
                        [-15.531, -9.743],
                        [-25.261, -8.893],
                        [-10.361, 13.287],
                        [-8.421, 16.287],
                        [-28.441, -11.003],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.08, 1.51],
                        [-3.08, -3.43],
                        [27.82, -3.77],
                        [-0.82, 1.55],
                        [3.49, 2.1],
                        [1.67, -1.18],
                      ],
                      o: [
                        [2.3, -1.51],
                        [3.85, 4.28],
                        [0.92, -1.2],
                        [19.3, -3.57],
                        [-2.79, -1.69],
                        [0.01, -1.4],
                      ],
                      v: [
                        [15.45, -14.212],
                        [26.19, -14.462],
                        [8.21, 15.288],
                        [10.84, 11.168],
                        [24.08, -11.292],
                        [15.56, -9.852],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.980391979218, 0.858824014664, 0.588235020638, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape',
              np: 4,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 19',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [32.356, 60.848, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.43, 3.73],
                        [-0.58, -0.16],
                        [-1, 0.64],
                        [-1.37, -0.03],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.02, -0.73],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.52, 0.12],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.54, 0.23],
                        [0.83, -0.29],
                        [-0.73, 10.47],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.25, -0.03],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.03, -1.49],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.98, -1.07],
                      ],
                      v: [
                        [-2.961, -13.137],
                        [-0.861, -13.187],
                        [1.509, -13.937],
                        [4.539, -0.827],
                        [4.629, -0.837],
                        [6.499, -0.877],
                        [6.549, 0.993],
                        [11.549, 0.883],
                        [13.159, 3.023],
                        [13.399, 13.313],
                        [-13.161, 13.933],
                        [-13.401, 3.643],
                        [-11.891, 1.423],
                        [-7.201, 1.313],
                        [-7.241, -0.557],
                        [-5.371, -0.597],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078026295, 0.337255001068, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [31.239, 26.625, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.05, 1.13],
                        [-1.53, 1.18],
                        [0, 0],
                        [1.02, -1.06],
                        [0.01, -1.41],
                        [2.66, -4.99],
                        [0.92, -1.2],
                        [1.81, -1.15],
                        [0.83, -0.29],
                        [0.55, 0.23],
                        [1.74, 2.26],
                        [0.59, 1.07],
                        [0.12, 6.85],
                      ],
                      o: [
                        [-3.8, -2.41],
                        [0, 0],
                        [2.57, 2.69],
                        [0.08, 1.51],
                        [-0.07, 9.15],
                        [-0.82, 1.55],
                        [-1.89, 2.46],
                        [-1.01, 0.64],
                        [-0.59, -0.16],
                        [-2.59, -1.09],
                        [-0.71, -0.94],
                        [-3.77, -6.87],
                        [-0.03, -1.36],
                      ],
                      v: [
                        [-15.051, -13.874],
                        [-15.201, -20.424],
                        [15.729, -21.144],
                        [15.889, -14.594],
                        [15.989, -10.224],
                        [11.279, 10.786],
                        [8.649, 14.906],
                        [2.949, 20.276],
                        [0.579, 21.026],
                        [-1.531, 21.076],
                        [-7.981, 15.916],
                        [-9.931, 12.906],
                        [-15.091, -10.124],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078026295, 0.337255001068, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [31.239, 9.119, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.53, 1.18],
                        [0, 0],
                        [1.02, -1.06],
                      ],
                      o: [
                        [-3.8, -2.4],
                        [0, 0],
                        [2.56, 2.69],
                        [0, 0],
                      ],
                      v: [
                        [-15.051, 3.633],
                        [-15.201, -2.917],
                        [15.739, -3.637],
                        [15.889, 2.913],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.549019992352, 0.490195989609, 0.360783994198, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Path 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [113.805, 72.319, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.231, 3.28],
                        [-0.01, 0],
                        [0, 6.72],
                        [-3.24, 0.96],
                        [-8.48, -0.38],
                        [-3.24, 3.04],
                        [2.138, -15.211],
                      ],
                      o: [
                        [0, 0],
                        [-10.61, 0],
                        [0, -5.38],
                        [12.07, 9.45],
                        [8.48, 0.39],
                        [0, 0],
                        [-1.976, 14.059],
                      ],
                      v: [
                        [-16.299, 30.38],
                        [-34.409, 30.38],
                        [-86.539, -6.61],
                        [-76.819, -16.22],
                        [-29.999, 12.6],
                        [-9.679, 3.96],
                        [-1.329, 18.622],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.533333003521, 0.662744998932, 0.913725018501, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [28.379, 27.596, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.39, 1.56],
                        [-1.03, -0.89],
                        [-1.62, -2.71],
                        [-5.69, 4.04],
                        [-1.16, -0.09],
                        [3.38, 6.6],
                      ],
                      o: [
                        [0.81, 0.79],
                        [-2.64, 1.47],
                        [7.52, 12.7],
                        [1.18, 0.28],
                        [-16.27, 15.12],
                        [-2.7, -5.29],
                      ],
                      v: [
                        [-17.848, 4.82],
                        [-15.088, 7.36],
                        [-20.968, 15.17],
                        [5.492, 18.92],
                        [9.022, 19.48],
                        [-24.658, 16.12],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.18, 0.95],
                        [-4.61, 0],
                        [15.79, -23.21],
                        [0.6, 1.64],
                        [3.89, -1.18],
                        [0.24, -2.04],
                      ],
                      o: [
                        [0.41, -2.71],
                        [5.75, 0],
                        [-0.28, -1.49],
                        [10.25, -16.74],
                        [-3.12, 0.95],
                        [-1.04, -0.95],
                      ],
                      v: [
                        [2.305, -18.67],
                        [9.305, -26.82],
                        [19.405, 6.45],
                        [18.095, 1.74],
                        [10.245, -23.13],
                        [5.625, -15.82],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.980391979218, 0.858824014664, 0.588235020638, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape',
              np: 4,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 19',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60.659, 56.886, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.07, 2.17],
                        [-0.51, 0.33],
                        [-0.2, 1.17],
                        [-0.94, 1],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.55, -0.48],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.26, 0.47],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.53, -0.25],
                        [0.34, -0.81],
                        [7.29, 7.54],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.82, -0.95],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.14, -0.97],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.2, -2.92],
                      ],
                      v: [
                        [-15.307, -10.913],
                        [-13.937, -12.513],
                        [-12.907, -14.773],
                        [-1.137, -8.263],
                        [-1.077, -8.333],
                        [0.143, -9.753],
                        [1.563, -8.533],
                        [4.813, -12.333],
                        [7.493, -12.093],
                        [15.303, -5.393],
                        [-1.997, 14.777],
                        [-9.807, 8.067],
                        [-10.447, 5.457],
                        [-7.397, 1.907],
                        [-8.817, 0.687],
                        [-7.597, -0.743],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078026295, 0.337255001068, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [26.88, 25.9, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.8, 0.8],
                        [-0.15, 1.93],
                        [0, 0],
                        [-0.11, -1.47],
                        [-1.03, -0.94],
                        [-1.93, -5.32],
                        [-0.28, -1.48],
                        [0.36, -2.12],
                        [0.34, -0.81],
                        [0.54, -0.25],
                        [2.85, 0.22],
                        [1.19, 0.28],
                        [5.18, 4.49],
                      ],
                      o: [
                        [-4.34, 1.22],
                        [0, 0],
                        [3.72, -0.11],
                        [1.17, 0.95],
                        [6.76, 6.18],
                        [0.6, 1.65],
                        [0.57, 3.05],
                        [-0.19, 1.17],
                        [-0.51, 0.33],
                        [-2.54, 1.2],
                        [-1.17, -0.1],
                        [-7.63, -1.78],
                        [-1.02, -0.89],
                      ],
                      v: [
                        [-16.345, 6.511],
                        [-21.315, 2.251],
                        [-1.175, -21.239],
                        [3.805, -16.969],
                        [7.115, -14.129],
                        [19.595, 3.431],
                        [20.895, 8.141],
                        [21.075, 15.971],
                        [20.055, 18.231],
                        [18.675, 19.831],
                        [10.525, 21.181],
                        [6.985, 20.611],
                        [-13.595, 9.051],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.796078026295, 0.337255001068, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [18.115, 18.662, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.15, 1.93],
                        [0, 0],
                        [-0.11, -1.47],
                      ],
                      o: [
                        [-4.34, 1.22],
                        [0, 0],
                        [3.72, -0.11],
                        [0, 0],
                      ],
                      v: [
                        [-7.579, 13.749],
                        [-12.549, 9.489],
                        [7.591, -14.001],
                        [12.571, -9.731],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.549019992352, 0.490195989609, 0.360783994198, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Path 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [12.305, 152.819, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.712, 0.891],
                        [0, 0],
                        [-1.41, -3.46],
                        [7.07, -12.49],
                        [8.703, 1.79],
                      ],
                      o: [
                        [0, 0],
                        [4.56, -1.44],
                        [1.77, 4.32],
                        [0.948, 0.474],
                        [-7.452, -1.532],
                      ],
                      v: [
                        [31.841, -21.5],
                        [35.381, -101.25],
                        [50.841, -101.25],
                        [52.291, -17.33],
                        [40.742, -11.609],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.533333003521, 0.662744998932, 0.913725018501, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [20.041, 34.063, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.61, -4.28],
                        [3.71, -1.3],
                        [3.72, 1.75],
                        [2.44, 3.63],
                        [-1.24, -2.27],
                        [1.97, 6.29],
                        [-3.97, 2.17],
                        [-2.1, 5.58],
                        [-3.31, -3.35],
                        [-3.98, 2.17],
                        [-2.28, -6.86],
                        [0.66, -5.52],
                        [-0.94, -1.7],
                        [-0.99, -3.15],
                      ],
                      o: [
                        [1.29, 3.42],
                        [-5.02, 0.75],
                        [-5.89, -2.79],
                        [-3.98, -5.93],
                        [1.25, 2.27],
                        [-1.97, -6.29],
                        [3.97, -2.18],
                        [2.11, -5.57],
                        [3.31, 3.35],
                        [3.97, -2.18],
                        [2.29, 6.86],
                        [-0.66, 5.53],
                        [0.93, 1.7],
                        [0.98, 3.14],
                      ],
                      v: [
                        [18.238, 24.494],
                        [8.708, 32.664],
                        [-4.412, 30.734],
                        [-16.972, 19.444],
                        [-14.192, 16.444],
                        [-14.052, 4.574],
                        [-8.042, -12.726],
                        [-3.012, -25.076],
                        [3.288, -27.046],
                        [7.718, -32.416],
                        [16.758, -22.626],
                        [7.458, -0.576],
                        [12.908, 13.404],
                        [6.978, 17.394],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.294117987156, 0.325489997864, 0.380391985178, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [42.039, 50.865, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.54, -4.21],
                        [5.23, 5.5],
                        [0.99, 1.82],
                        [6.35, -0.53],
                        [-3.72, 1.3],
                        [3.87, 3.04],
                        [-2.83, 1.55],
                        [-3.83, -1.6],
                        [-2.01, 0.36],
                        [0.71, -1.86],
                        [7.01, -10.91],
                      ],
                      o: [
                        [-4.06, 15.2],
                        [-0.76, -2.29],
                        [-1.24, -2.27],
                        [-6.35, 0.53],
                        [3.71, -1.3],
                        [-3.88, -3.04],
                        [2.84, -1.55],
                        [3.82, 1.59],
                        [1.61, -0.29],
                        [4.17, 0.42],
                        [-7, 10.91],
                      ],
                      v: [
                        [15.571, 21.439],
                        [-5.179, 23.949],
                        [-8.699, 14.819],
                        [-8.259, 3.519],
                        [-15.129, -2.301],
                        [-15.399, -9.531],
                        [-11.259, -30.231],
                        [-2.109, -25.651],
                        [10.071, -24.951],
                        [9.971, -19.741],
                        [13.711, -4.831],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929412007332, 0.780391991138, 0.77254897356, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [150.399, 17.392, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-7.34, 5.37],
                        [0, 0],
                        [4.89, -4.88],
                      ],
                      o: [
                        [-17.62, -11.72],
                        [0, 0],
                        [11.75, 12.9],
                        [0, 0],
                      ],
                      v: [
                        [-71.073, 15.388],
                        [-71.073, -15.392],
                        [74.317, -15.392],
                        [74.317, 15.388],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.549019992352, 0.490195989609, 0.360783994198, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Shape 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [151.225, 98.529, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-15.97, -7.32],
                        [0.03, -6.38],
                        [4.7, -14.08],
                        [-31.82, -7.91],
                        [-3.25, -4.46],
                        [-13.22, 32.25],
                      ],
                      o: [
                        [-0.38, 5.29],
                        [-13.31, -4.89],
                        [-22.32, 65.67],
                        [2.63, 5.07],
                        [-103.72, -11.78],
                        [10.57, -25.8],
                      ],
                      v: [
                        [-71.67, -65.812],
                        [-72.31, -48.212],
                        [-118.12, -45.282],
                        [-50.56, 60.538],
                        [-41.75, 74.878],
                        [-132.8, -55.542],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.22, 7.1],
                        [-14.09, -16.42],
                        [131.09, -14.66],
                        [-4.04, 7.19],
                        [16.16, 10.27],
                        [8.01, -5.38],
                      ],
                      o: [
                        [10.95, -6.85],
                        [17.63, 20.52],
                        [4.45, -5.54],
                        [91.04, -14.66],
                        [-12.92, -8.2],
                        [0.21, -6.58],
                      ],
                      v: [
                        [73.715, -65.803],
                        [124.195, -65.803],
                        [36.465, 71.987],
                        [49.285, 52.927],
                        [113.915, -51.153],
                        [73.725, -45.283],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.980391979218, 0.858824014664, 0.588235020638, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Shape',
              np: 4,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [150.624, 100.334, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.37, 5.29],
                        [-7.34, 5.37],
                        [0, 0],
                        [4.89, -4.88],
                        [0.21, -6.58],
                        [13.04, -23.16],
                        [4.45, -5.54],
                        [8.64, -5.22],
                        [-6.915, -17.103],
                        [-0.125, -0.305],
                        [0.086, 0.045],
                        [-5.892, 0.031],
                        [-0.014, -7.365],
                        [-0.18, -0.039],
                        [0.535, -0.064],
                        [-0.163, 18.219],
                        [-7.75, 0.107],
                        [-3.631, 0.038],
                        [0.058, -0.008],
                        [-3.031, 11.2],
                        [0.154, 0.133],
                        [7.91, 10.85],
                        [2.64, 5.07],
                        [-0.16, 32.21],
                      ],
                      o: [
                        [-17.62, -11.72],
                        [0, 0],
                        [11.75, 12.9],
                        [0.22, 7.11],
                        [-1.36, 43],
                        [-4.04, 7.19],
                        [-9.12, 11.35],
                        [-3.074, 1.858],
                        [3.838, 9.493],
                        [0.703, 1.98],
                        [-0.046, 0.013],
                        [9.483, -0.05],
                        [0.034, 18.268],
                        [0.345, 0.163],
                        [-0.654, 0.079],
                        [0.063, -7.032],
                        [6.754, -0.094],
                        [-0.131, -0.087],
                        [9.683, 1.617],
                        [2.179, -8.053],
                        [-11.504, -6.466],
                        [-3.24, -4.45],
                        [-16.97, -32.68],
                        [0.03, -6.38],
                      ],
                      v: [
                        [-71.073, -67.617],
                        [-71.073, -98.397],
                        [74.317, -98.397],
                        [74.317, -67.617],
                        [74.327, -47.087],
                        [49.887, 51.123],
                        [37.067, 70.183],
                        [9.697, 94.773],
                        [12.488, 149.169],
                        [29.75, 156.971],
                        [30.039, 165.621],
                        [52.393, 165.591],
                        [61.217, 182.023],
                        [61.28, 224.254],
                        [-63.648, 224.468],
                        [-63.312, 179.449],
                        [-54.249, 165.684],
                        [-34.244, 165.378],
                        [-34.308, 156.799],
                        [-15.344, 144.467],
                        [-12.278, 97.908],
                        [-41.153, 73.063],
                        [-49.963, 58.733],
                        [-71.703, -50.017],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: { a: 0, k: [1, 0.8, 0.4, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'g',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              n: '0p667_1_0p333_0',
              t: 0,
              s: [951.876, 382.366, 0],
              e: [951.876, 341.366, 0],
              to: [0, -6.83333349227905, 0],
              ti: [0, -2.83333325386047, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              n: '0p667_1_0p333_0',
              t: 45,
              s: [951.876, 341.366, 0],
              e: [951.876, 399.366, 0],
              to: [0, 2.83333325386047, 0],
              ti: [0, -1.16666662693024, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              n: '0p667_1_0p333_0',
              t: 90,
              s: [951.876, 399.366, 0],
              e: [951.876, 348.366, 0],
              to: [0, 1.16666662693024, 0],
              ti: [0, 2.83333325386047, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              n: '0p667_1_0p333_0',
              t: 135,
              s: [951.876, 348.366, 0],
              e: [951.876, 382.366, 0],
              to: [0, -2.83333325386047, 0],
              ti: [0, -5.66666650772095, 0],
            },
            { t: 180 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [130, 211, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 260,
      h: 422,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [993.17, 397.108, 0],
              e: [1083.17, 394.108, 0],
              to: [15, -0.5, 0],
              ti: [-9.94303512573242, -142.149948120117, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [1083.17, 394.108, 0],
              e: [1174.28, 749.792, 0],
              to: [7.90503978729248, 113.013885498047, 0],
              ti: [55.7971000671387, -153.167282104492, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [1174.28, 749.792, 0],
              e: [1147.17, 1238.108, 0],
              to: [-87.4352798461914, 240.016494750977, 0],
              ti: [-5.71911764144897, -75.4208602905273, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.951, 9.818],
                    [11.609, 3.828],
                    [0.819, -9.822],
                    [-11.611, 1.118],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [999.354, 410.433, 0],
              e: [1134.354, 373.433, 0],
              to: [22.5, -6.16666650772095, 0],
              ti: [-12.2764949798584, -137.983932495117, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [1134.354, 373.433, 0],
              e: [1282.584, 756.779, 0],
              to: [11.4443817138672, 128.631240844727, 0],
              ti: [-4.15997362136841, -135.90266418457, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [1282.584, 756.779, 0],
              e: [1198.354, 1217.433, 0],
              to: [5.76537084579468, 188.349578857422, 0],
              ti: [-5.69962930679321, -75.1638641357422, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.735, 8.512],
                    [5.815, 9.242],
                    [6.735, -9.238],
                    [-4.925, -9.108],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [984.839, 384.563, 0],
              e: [1169.839, 332.563, 0],
              to: [30.8333339691162, -8.66666698455811, 0],
              ti: [-33.5282096862793, -134.246444702148, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [1169.839, 332.563, 0],
              e: [1319.426, 733.494, 0],
              to: [37.3477401733398, 149.539794921875, 0],
              ti: [80.2878494262695, -143.720916748047, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [1319.426, 733.494, 0],
              e: [1233.839, 1176.563, 0],
              to: [-97.3067474365234, 174.185958862305, 0],
              ti: [-5.69375371932983, -75.0863723754883, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-4.171, 5.767],
                    [9.928, 5.927],
                    [10.318, -5.923],
                    [-10.322, -3.133],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [987.75, 389.5, 0],
              e: [735.75, 508.5, 0],
              to: [-42, 19.8333339691162, 0],
              ti: [15.9822511672974, -125.859870910645, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [735.75, 508.5, 0],
              e: [641.541, 791.005, 0],
              to: [-20.8538646697998, 164.223709106445, 0],
              ti: [15.7144908905029, -157.229370117188, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [641.541, 791.005, 0],
              e: [737.75, 1108.5, 0],
              to: [-18.8931789398193, 189.033340454102, 0],
              ti: [-0.18250241875648, -54.7507247924805, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-11.25, 9.75],
                    [-11.25, -14.25],
                    [11.25, -6.75],
                    [3, 14.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [988.125, 400, 0],
              e: [1126.125, 270, 0],
              to: [23, -21.6666660308838, 0],
              ti: [-2.22080636024475, -123.650764465332, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [1126.125, 270, 0],
              e: [1228.925, 645.256, 0],
              to: [1.86954915523529, 104.093353271484, 0],
              ti: [57.1274108886719, -157.971862792969, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [1228.925, 645.256, 0],
              e: [1190.125, 1114, 0],
              to: [-92.5926513671875, 256.042297363281, 0],
              ti: [-5.63869047164917, -74.360237121582, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-7.875, 9],
                    [-16.125, -6.75],
                    [16.125, -9],
                    [9.375, 6],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [981.936, 379.652, 0],
              e: [664.936, 314.652, 0],
              to: [-110.833335876465, -196.83332824707, 0],
              ti: [-66.2000198364258, -102.05037689209, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [664.936, 314.652, 0],
              e: [574.584, 708.53, 0],
              to: [85.3139190673828, 131.515319824219, 0],
              ti: [90.9188919067383, -138.065185546875, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [574.584, 708.53, 0],
              e: [644.936, 1226.652, 0],
              to: [-145.589324951172, 221.085144042969, 0],
              ti: [0, 0, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.25, -4.5],
                    [0, 0],
                    [-7.5, -0.5],
                    [2, -8.5],
                    [-3.5, -2.25],
                    [14.25, 9],
                  ],
                  o: [
                    [0, 0],
                    [2.4, -3.6],
                    [2.4, 4.8],
                    [3, 0],
                    [3, -3],
                    [-11.4, -7.2],
                  ],
                  v: [
                    [-22.936, -19.102],
                    [-16.936, -8.602],
                    [8.564, -11.602],
                    [5.564, 19.898],
                    [19.814, 26.648],
                    [13.064, -23.602],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [996.5, 401.5, 0],
              e: [1153.5, 499.5, 0],
              to: [139.16667175293, -69.6666641235352, 0],
              ti: [15.5710983276367, -85.9535522460938, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [1153.5, 499.5, 0],
              e: [1094.755, 861.245, 0],
              to: [-24.2963619232178, 134.117614746094, 0],
              ti: [5.39423179626465, -150.769454956055, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [1094.755, 861.245, 0],
              e: [1177.5, 1177.5, 0],
              to: [-6.38916301727295, 178.577911376953, 0],
              ti: [0, 0, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.11, -2.67],
                    [-2.96, 4.36],
                    [-1.11, 5.33],
                    [2.47, 0.61],
                    [-0.36, -6.18],
                  ],
                  o: [
                    [1.18, 0.58],
                    [5.92, -1.82],
                    [2.37, -3.49],
                    [-1.48, 0.29],
                    [-2.66, 1.75],
                    [0, 0],
                  ],
                  v: [
                    [-13.5, 9.27],
                    [-8.69, 18],
                    [7.21, 3.82],
                    [13.5, -16.54],
                    [4.25, -18],
                    [2.03, 2.73],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [990.25, 425.75, 0],
              e: [1175.25, 447.75, 0],
              to: [102.833335876465, -122.333335876465, 0],
              ti: [-6.07192802429199, -86.9057159423828, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [1175.25, 447.75, 0],
              e: [1221.715, 841.857, 0],
              to: [8.5975399017334, 123.054061889648, 0],
              ti: [47.3162612915039, -184.682800292969, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [1221.715, 841.857, 0],
              e: [1307.25, 1285.75, 0],
              to: [-71.4641265869141, 278.935729980469, 0],
              ti: [0, 0, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [1.88, -4.93],
                    [5.25, 3.83],
                    [-2.25, 6.75],
                    [-5.25, -6.75],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [991.125, 401.125, 0],
              e: [817.125, 259.125, 0],
              to: [-6, -120.666664123535, 0],
              ti: [87, -41.3333320617676, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [817.125, 259.125, 0],
              e: [654.678, 601.096, 0],
              to: [-15.7054758071899, 7.46160507202148, 0],
              ti: [-113.89599609375, -175.464385986328, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [654.678, 601.096, 0],
              e: [797.125, 1171.125, 0],
              to: [182.487686157227, 281.134460449219, 0],
              ti: [0, 0, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [2.25, 5.5],
                    [6, -9],
                    [2.25, -11],
                    [-5, -1.25],
                    [0.75, 12.75],
                  ],
                  o: [
                    [-2.4, -1.2],
                    [-12, 3.75],
                    [-4.8, 7.2],
                    [3, -0.6],
                    [5.4, -3.6],
                    [0, 0],
                  ],
                  v: [
                    [27.375, -19.125],
                    [17.625, -37.125],
                    [-14.625, -7.875],
                    [-27.375, 34.125],
                    [-8.625, 37.125],
                    [-4.125, -5.625],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [982.89, 384.369, 0],
              e: [1236.89, 385.369, 0],
              to: [73.3333358764648, -121.833335876465, 0],
              ti: [19.7401962280273, -141.634963989258, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [1236.89, 385.369, 0],
              e: [1347.739, 724.754, 0],
              to: [-11.9246520996094, 85.5588150024414, 0],
              ti: [-95.6911239624023, -187.679306030273, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [1347.739, 724.754, 0],
              e: [1300.89, 1229.369, 0],
              to: [119.159614562988, 233.708114624023, 0],
              ti: [0, 0, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [9, 0.6],
                    [1, 7.25],
                    [0, -3.6],
                    [-1.5, -3.75],
                    [-3.6, 0.6],
                    [-9, -8.25],
                    [0.75, -6.75],
                    [0, 0],
                    [3.75, 4.75],
                    [6.299, -3.913],
                    [1.774, -1.311],
                  ],
                  o: [
                    [-9, -0.6],
                    [5.25, -1.5],
                    [0, 3.6],
                    [6.25, -4],
                    [4.5, -0.75],
                    [7.2, 6.6],
                    [0, 0],
                    [0, -7.2],
                    [-4.979, 0],
                    [-2.81, 1.745],
                    [-2.75, 0.25],
                  ],
                  v: [
                    [-27.14, 5.121],
                    [-41.39, -17.379],
                    [-25.64, -17.379],
                    [-21.14, -1.629],
                    [2.11, -14.379],
                    [34.36, -9.129],
                    [41.11, 19.371],
                    [29.86, 15.621],
                    [18.61, -7.629],
                    [-0.565, 0.35],
                    [-7.64, 5.121],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [973.25, 365, 0],
              e: [737.25, 432, 0],
              to: [-58.3333320617676, -59.3333320617676, 0],
              ti: [15.5815134048462, -132.888198852539, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [737.25, 432, 0],
              e: [809.935, 859.107, 0],
              to: [-24.9164524078369, 212.501983642578, 0],
              ti: [3.95723152160645, -203.670959472656, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [809.935, 859.107, 0],
              e: [717.25, 1344, 0],
              to: [-4.68123483657837, 240.933990478516, 0],
              ti: [0, 0, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-7.5, 12.75],
                    [-14.25, -3],
                    [9, -12.75],
                    [14.25, 2.25],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [992.875, 415.625, 0],
              e: [1180.875, 287.625, 0],
              to: [2.33333325386047, -103.833335876465, 0],
              ti: [-55.7662620544434, -64.9507217407227, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [1180.875, 287.625, 0],
              e: [1234.303, 634.152, 0],
              to: [73.0201110839844, 85.0462036132812, 0],
              ti: [-71.5893936157227, -180.220397949219, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [1234.303, 634.152, 0],
              e: [1244.875, 1131.625, 0],
              to: [82.6001358032227, 207.939041137695, 0],
              ti: [0, 0, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-13.875, 8.625],
                    [-4.125, -8.625],
                    [13.875, -8.625],
                    [6.375, 8.625],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [982.75, 370.625, 0],
              e: [771.75, 365.625, 0],
              to: [-35.6666679382324, -105.333335876465, 0],
              ti: [7.9813027381897, -169.310913085938, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [771.75, 365.625, 0],
              e: [689.692, 788.433, 0],
              to: [-13.2903537750244, 281.934173583984, 0],
              ti: [-5.05010175704956, -205.983413696289, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: '0p833_0p833_0p167_0p167',
              t: 106,
              s: [689.692, 788.433, 0],
              e: [751.75, 1277.625, 0],
              to: [6.64294528961182, 270.952301025391, 0],
              ti: [0, 0, 0],
            },
            { t: 179 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-3.75, 10.125],
                    [-10.5, -7.875],
                    [4.5, -13.875],
                    [10.5, 13.875],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.874509990215, 0.2666670084, 0.639216005802, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 0,
      nm: 'cup',
      refId: 'comp_4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [982.36, 696.522, 0], ix: 2 },
        a: { a: 0, k: [150, 163, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 300,
      h: 326,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}

export default AbstractCongratulationsAnimation
