import styled from '@emotion/styled'
import mq from 'components/styleComponents/breakpoints'

export const StripeCheckoutRedirectContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
`

export const StripeCheckoutContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mq['md']} {
    margin: 1rem;
  }
`

export const StripeCheckoutRedirectHeader = styled.h1`
  font-size: 4.5rem;
  font-weight: 600;
`

export const StripeCheckoutRedirectDescription = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const StripeCheckoutRedirectButton = styled.button`
  box-sizing: border-box;
  color: white;
  text-transform: uppercase;
  width: 300px;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  background: rgb(12, 78, 187);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.42);
  border-image: initial;
  border-radius: 8px;
  padding: 10px;

  ${mq['sm']} {
    width: 90%;
  }
`

export const ShapeContainer = styled.div`
  width: 500px;
  height: 500px;
  align-self: center;

  ${mq['md']} {
    display: none;
  }
`

export const StripeCheckoutImage = styled.img`
  height: 48px;
  margin-right: 1rem;
`
