import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { navigate } from 'gatsby'
import moment from 'moment'

// local imports
import { REGISTER_USER_TO_CLASS, GET_CLASSES_AS_STUDENT } from 'utils/graphql'
import UserContext from 'context/user/UserContext'

// style imports
import {
  AvailableClassesContainer,
  AvailableClassesContents,
  ClassCardContainer,
  RegisterButton,
} from 'components/styleComponents/PurchasePage/PurchaseStyles'

const AvailableClasses = ({ userId, purchase, email }) => {
  const { t } = useTranslation('paymentSuccessPage')

  const [registerUserToClass, { loading: registerLoading }] = useMutation(
    REGISTER_USER_TO_CLASS,
  )
  //class data - this is what give this component access to the available classes for the users purchased product.
  const [
    getClasses,
    {
      loading: getClassesLoading,
      error: getClassesError,
      data: getClassesData,
    },
  ] = useLazyQuery(GET_CLASSES_AS_STUDENT)

  const { updateUserFWAProduct } = useContext(UserContext)

  useEffect(() => {
    if (purchase?.priceId)
      getClasses({
        variables: {
          input: {
            status: ['OPEN', 'ACTIVE'],
            priceId: purchase.priceId,
          },
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase])

  const registerForClass = async (classId) => {
    try {
      const response = await registerUserToClass({
        variables: {
          input: { classId, studentId: userId, email },
        },
      })

      if (!response.data.registerUserToClass.success) {
        console.error('duplicate registration')
        return alert(t('duplicateRegistrationMessage'))
      }

      //update the firestore document with the class that's been registered
      await updateUserFWAProduct({
        purchaseId: purchase.purchaseId,
        purchaseDate: purchase.purchaseDate,
        classId,
        productId: purchase.productId,
        productName: purchase.productName,
        priceId: purchase.priceId,
        priceName: purchase.priceName,
        status: 'REGISTERED',
      })
      // send the user to the account page now that they've picked their class
      await navigate('/account/')
    } catch (err) {
      console.log('There was an error: ', err)
      alert(t('generalRegistrationErrorMessage'))
    }
  }

  return (
    <AvailableClassesContainer>
      <AvailableClassesContents>
        {getClassesError ? <p>{t('loadingError')}</p> : null}
        {getClassesLoading ? (
          <p>{t('loading')}</p>
        ) : (
          getClassesData?.getClasses?.classes?.map((oneClass, index) => {
            return oneClass?.students?.length >=
              oneClass?.maxStudents ? null : (
              <ClassCardContainer key={index}>
                <h3>
                  FLUENTWORLDS {oneClass?.category} {oneClass?.language}
                </h3>
                <p>
                  <b>{t('startDateLabel')}</b>{' '}
                  {moment(oneClass?.startDate).format('MMMM Do YYYY')}
                </p>
                <p>
                  <b>{t('endDateLabel')}</b>{' '}
                  {moment(oneClass?.endDate).format('MMMM Do YYYY')}
                </p>
                <br />
                <b>{t('classTimesLabel')}</b>
                <ul>
                  {oneClass?.days.map((day) => {
                    return (
                      <li>
                        {day?.dayOfWeek} {day?.timeOfDay}
                      </li>
                    )
                  })}
                </ul>
                <br />
                <p>
                  <b>{t('classDescriptionLabel')}</b>
                  <br /> {oneClass?.description}
                </p>
                <br />
                <p>
                  <b>{t('classStudentsLabel')}</b> {oneClass?.students?.length}/
                  {oneClass?.maxStudents}
                </p>
                <br />
                {registerLoading ? (
                  <p>{t('processing')}</p>
                ) : (
                  <RegisterButton
                    onClick={() => registerForClass(oneClass.id)}
                    key={index}
                  >
                    {t('register')}
                  </RegisterButton>
                )}
              </ClassCardContainer>
            )
          })
        )}
      </AvailableClassesContents>
    </AvailableClassesContainer>
  )
}

export default AvailableClasses
