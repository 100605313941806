// Module imports
import React, { useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useQueryParam, StringParam } from 'use-query-params'
import { InlineWidget } from 'react-calendly'

// local imports
import AvailableClasses from 'components/General/AvailableClasses'
import UserContext from 'context/user/UserContext'
import { useAuth0 } from 'utils/react-auth0-spa'
import Loading from 'components/General/Loading/Loading'
import GlobalStyles from 'components/styleComponents/GlobalStyles'
import mq from 'components/styleComponents/breakpoints'
import {
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectButton,
} from 'components/styleComponents/StripeCheckout'
import LottieShape from 'components/General/LottieShape'
import AbstractCongratulationsAnimation from 'components/General/Animations/AbstractCongratulationsAnimation'
import SubscriptionContext from 'context/subscription/SubscriptionContext'

const TitleContainer = styled.h1`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 3rem 0;
  text-align: center;

  p {
    width: 75%;
  }

  ${mq['sm']} {
    font-size: 2.5rem;
  }
`

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 2em;
`

const Success = () => {
  // UserContext enables this component to interact with the local user object and the firebase user.
  const {
    firestoreUserObject,
    fetchUserFromFirestore,
    userStateLoading,
    updateUserFWAProduct,
  } = useContext(UserContext)
  // subscriptionContext enables this component to interact with the local subscription object and with stripe/firebase to handle product/subscription details.
  const {
    getStripeSessionDetails,
    subscriptionStateLoading,
    stripeSessionDetails,
  } = useContext(SubscriptionContext)

  const { isAuthenticated, auth0Loading, auth0User } = useAuth0()
  const { t, ready } = useTranslation('paymentSuccessPage')
  const [stripeSessionId] = useQueryParam('session_id', StringParam)
  const fwaProduct = {
    purchaseId: stripeSessionDetails?.paymentIntent,
    purchaseDate: stripeSessionDetails?.purchaseDate,
    classId: '',
    productId: stripeSessionDetails?.productId,
    productName: stripeSessionDetails?.productName,
    priceId: stripeSessionDetails?.priceId,
    priceName: stripeSessionDetails?.priceName,
    status: 'PURCHASED',
  }

  // this useEffect get's the necessary data from firestore and Stripe to process a purchase.
  useEffect(() => {
    const handlePurchaseData = async () => {
      await fetchUserFromFirestore()
      // get the stripe session details from stripe
      await getStripeSessionDetails(stripeSessionId)
    }
    // ensure the component has the existing user data in firestore
    if (isAuthenticated && !auth0Loading) {
      handlePurchaseData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, auth0Loading])

  useEffect(() => {
    // now that we have all the needed data on the purchase - add it to firebase
    if (firestoreUserObject && stripeSessionDetails) {
      const addUserCallBack = async () => {
        let hasDuplicatePurchase = false
        if (Array.isArray(firestoreUserObject.fwaPurchases)) {
          // if the user already has purchases - check to see that this isn't a duplicate
          hasDuplicatePurchase = firestoreUserObject.fwaPurchases.some(
            (purchase) => {
              return purchase?.purchaseId === fwaProduct?.purchaseId
            },
          )

          if (!hasDuplicatePurchase) await updateUserFWAProduct(fwaProduct)
        } else {
          // if the user doesn't already have purchases. just add the purchase
          await updateUserFWAProduct(fwaProduct)
        }
      }
      // actually call the function now
      addUserCallBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestoreUserObject, stripeSessionDetails])

  const showLoading =
    auth0Loading || userStateLoading || subscriptionStateLoading

  return (
    <>
      {!ready ? null : (
        <>
          <Helmet>
            <title>{t('title')}</title>
          </Helmet>
          <Global styles={GlobalStyles} />
          {showLoading ? (
            <Loading />
          ) : (
            <main>
              <StripeCheckoutContentContainer>
                <LottieShape
                  size={300}
                  animationData={AbstractCongratulationsAnimation}
                />
                <TitleContainer>
                  <p>
                    {t('confirmationMessage', {
                      priceName: stripeSessionDetails?.priceName,
                    })}
                  </p>
                </TitleContainer>
                {/* here we show the tutoring calendly calendar if its an individual FWA purchase, if not we show the availble classes for the group class purchase.  */}
                {stripeSessionDetails?.priceName?.includes('Individual') ? (
                  <InlineWidget url={process.env.GATSBY_CALENDLY_URL} />
                ) : (
                  <AvailableClasses
                    userId={auth0User.sub}
                    purchase={fwaProduct}
                    email={auth0User.email}
                  ></AvailableClasses>
                )}

                <ButtonWrapper>
                  <Link to="/account">
                    <StripeCheckoutRedirectButton>
                      {t('goBackButton')}
                    </StripeCheckoutRedirectButton>
                  </Link>
                </ButtonWrapper>
              </StripeCheckoutContentContainer>
            </main>
          )}
        </>
      )}
    </>
  )
}

export default Success
